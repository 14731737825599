import { createTheme } from '@mui/material/styles';

import { paletteMui } from './palette';

export const theme = createTheme({
    palette: paletteMui,
    spacing: 2,
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '0'
                }
            },
            containedPrimary: {
                backgroundColor: '#7f52ff'
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    alignItems: 'center'
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: '8px'
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                dividers: {
                    padding: '8px',
                    minHeight: '120px'
                }
            }
        }
    }
});
